import { on } from "delegated-events";
import { Utils } from "../../assets/js/helpers/utils";
import { ZEvents } from "../../assets/js/helpers/events";
import { AppState } from "../../assets/js/helpers/app-state";

export class OffCanvasNav {
  cssClass = "c-offcanvasnav";
  offCanvasSelectorTrigger = "[data-offcanvas-trigger]";
  cssClassBodyOpen = "offcanvas--open";
  isOpen = false;

  constructor(el = null) {
    this.el = el;
    this.init();
  }

  toggle(status){
    switch (status){
        case "open":
            this.isOpen = true;
            this.el.classList.add(`${this.cssClass}--open`);
            document.body.classList.add('overflow-hidden');
            document.body.classList.add(this.cssClassBodyOpen);
            AppState.setState("offCanvasOpen", this.isOpen);
            ZEvents.triggerEvent("ui","offcanvasShown");
        break;
        case "close":
            this.isOpen = false;
            this.el.classList.remove(`${this.cssClass}--open`);
            document.body.classList.remove('overflow-hidden');
            document.body.classList.remove(this.cssClassBodyOpen);
            AppState.setState("offCanvasOpen", this.isOpen);
            ZEvents.triggerEvent("ui","offcanvasClosed");
        break;
        case "auto":
            if( this.isOpen == false ){
                this.isOpen = true;
                this.el.classList.add(`${this.cssClass}--open`);
                document.body.classList.add('overflow-hidden');
                document.body.classList.add(this.cssClassBodyOpen);
                ZEvents.triggerEvent("ui","offcanvasShown");
            }else{
                this.isOpen = false;
                this.el.classList.remove(`${this.cssClass}--open`);
                document.body.classList.remove('overflow-hidden');
                document.body.classList.remove(this.cssClassBodyOpen);
                ZEvents.triggerEvent("ui","offcanvasClosed");
            }
            AppState.setState("offCanvasOpen", this.isOpen);
        break;
    }

  }

  init(){

    var currentComponent = this;

    //Whenever an overlay shown event is fired, hide the off canvas
    document.addEventListener(`${Utils.getVarNS()}.overlay.shown`, () =>{
        if( currentComponent.isOpen ){
            currentComponent.toggle("close");
            document.querySelectorAll( currentComponent.offCanvasSelectorTrigger).forEach( element => {
                if( element.getAttribute('data-offcanvas-trigger') == currentComponent.el.getAttribute('id') ){
                    element.classList.remove("is-active");
                }
            });
        }
    });

    //Registering events for offcanvas show/hide
    ZEvents.addCustomEvent("ui", "offcanvasShown", "offcanvas.shown");
    ZEvents.addCustomEvent("ui", "offcanvasClosed", "offcanvas.closed");

    on("click", this.offCanvasSelectorTrigger, (e) => {
        var eventTarget = e.currentTarget;
        var offCanvasName = e.currentTarget.getAttribute('data-offcanvas-trigger');
        if( eventTarget.classList.contains('is-active')){
            eventTarget.classList.remove('is-active');
        }else{
            eventTarget.classList.add('is-active');
        }
        if( this.el.getAttribute('id') == offCanvasName ){
            this.toggle("auto");
        }
    });
  }

}
