// Common
import { Modal } from "bootstrap";

// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";
import { ZEvents } from "./helpers/events";
import { AppState } from "./helpers/app-state";
//Vendor Helpers
import { FancyboxHelper } from "./helpers/vendors/fancybox";

// Components
import { Slider } from "../../components/slider/slider";
import { Header } from "../../components/header/header";
import { NavBar1 } from "../../components/navbar1/navbar1";
import { Icon } from "../../components/icon/icon";
import { SearchOverlay } from "../../components/searchoverlay/searchoverlay";
import { OffCanvasNav } from "../../components/offcanvasnav/offcanvasnav";
import { ComparisonSlider } from "../../components/comparisonslider/comparisonslider";

//Load Vendors
FancyboxHelper.init();

//Load app state
AppState.boot();

//Loading events
ZEvents.loadCustomEvents();

// Registering components
Component.register("Header", Header);
Component.register("Slider", Slider);
Component.register("NavBar1", NavBar1);
Component.register("Icon", Icon);
Component.register("OffCanvasNav",OffCanvasNav);
Component.register("SearchOverlay", SearchOverlay);
Component.register("ComparisonSlider", ComparisonSlider);

//Modules
import { Tabs } from "./modules/tabs";
import { SelectLink } from "./modules/select-link";

Tabs.init();
SelectLink.init();

init();
