export class Ajax {

  static get(url) {
    return fetch(url)
      .then(response => response.json())
      .catch(error => console.error(error));
  }

  static post(url, data, contentType = "x-www-form-urlencoded") {

    switch( contentType ){
      case "x-www-form-urlencoded":
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams(formData).toString()
        })
        .then(response => response.json())
        .catch(error => console.error(error));
      break;
      case "json":
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.error(error));
      break;
    }

  }

}

/*
javascript

Ajax.get("https://api.example.com/data").then(response => {
  console.log(response);
});

let data = {
  name: "John Doe",
  age: 30
};

Ajax.post("https://api.example.com/data", data).then(response => {
  console.log(response);
});

*/