import { Utils } from "../../assets/js/helpers/utils";
import { AppState } from "../../assets/js/helpers/app-state";
import { Ajax } from "../../assets/js/helpers/ajax";
import { Modal } from "bootstrap";
import { ZEvents } from "../../assets/js/helpers/events";

export class SearchOverlay {

    cssClass = "c-searchoverlay";
    isOpen = false;
    isTyping = false;
    typingFieldSelector = `[data-search-input]`;
    tplSearchResult = null;
    searchResultsStage = null;
    noResultsLabel = null;
    loader = null;
    searchEndPoint = "";
    
    constructor(el = null) {
        this.el = el;
        this.searchEndPoint = this.el.getAttribute("data-search-endpoint");
        this.loader = this.el.querySelector("[data-loader]");
        this.tplSearchResult = this.el.querySelector("[data-search-result-tpl]").innerHTML;
        this.searchResultsStage = this.el.querySelector("[data-search-results]");
        this.noResultsLabel = this.el.querySelector("[data-no-results]");
        this.init();
        AppState.setState("searchOverlayOpen", false);
    }

    init(){

        var currentComponent = this;
        var searchInput = this.el.querySelector(this.typingFieldSelector);

        this.el.addEventListener("shown.bs.modal", () => {
            currentComponent.isOpen = true;
            AppState.setState("searchOverlayOpen", true);
        });
        this.el.addEventListener("hide.bs.modal", () =>{
            currentComponent.el.classList.remove(`${currentComponent.cssClass}--typing`);
            currentComponent.isOpen = false;
            AppState.setState("searchOverlayOpen", false );
        });
        
        document.addEventListener(`${Utils.getVarNS()}.offcanvas.shown`, () =>{
            if( AppState.getState("searchOverlayOpen") == true ){
                Modal.getInstance(currentComponent.el).hide();
            }
        });

        searchInput.addEventListener("keyup", (e) => {
            var target = e.currentTarget;

            // Ignore keys that are not useful for the query
            if (!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey && e.key !== 'Tab' &&
            (e.keyCode < 37 || e.keyCode > 40) && e.keyCode !== 13) {
                if( target.value !== '' ){
                    currentComponent.el.isTyping = true;
                    currentComponent.el.classList.add(`${currentComponent.cssClass}--typing`);
                    currentComponent.el.classList.add(`${currentComponent.cssClass}--searching`);
                    this.noResultsLabel.classList.remove("--show");
                    this.loader.classList.add("--active");
                    Ajax.post(this.searchEndPoint, { "action" : "search", "queryString" : target.value }).then(response => {
                        this.loader.classList.remove("--active");
                        currentComponent.el.classList.remove(`${currentComponent.cssClass}--searching`);
                        this.searchResultsStage.innerHTML = "";
                        this.noResultsLabel.classList.remove("--show");
                        if( response.results.length > 0 ){
                            response.results.forEach( (element, index) => {
                                var searchResult = this.tplSearchResult;
                                searchResult = searchResult.replaceAll("%TITLE%", element.title);
                                searchResult = searchResult.replaceAll("%EYELET%", element.eyelet);
                                searchResult = searchResult.replaceAll("%IMGPLACEHOLDER%", element.img);
                                searchResult = searchResult.replaceAll("%URL%", element.url);
                                this.searchResultsStage.innerHTML += searchResult;
                            });
                        }else{
                            this.noResultsLabel.classList.add("--show");
                        }
                    });
                }else{
                    currentComponent.el.isTyping = false;
                }
            }
        });

    }

}