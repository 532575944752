import { Utils } from "./utils";
import { ZEvents } from "./events";
import { AppState } from "./app-state";

export class UI{

    static stickyFooter(){
        
        function stickyFooterWatch(){
            var stickyFooterElementSrc = document.querySelector("#zaux-content-wrapper");
            if( stickyFooterElementSrc !== null ){
                //stickyFooterElementSrc.style.border = "5px solid red";
                var config = stickyFooterElementSrc.getAttribute("data-settings");

                //Check if config is present or not
                if( config !== null ){
                    config = JSON.parse(config);
                }
                //If sticky footer is enabled in the configuration we boot it
                if( config.stickyFooter == true ){
                    var header = document.querySelector(".c-header");
                    var footer = document.querySelector(".c-footer");
                    stickyFooterElementSrc.style.minHeight = (window.innerHeight + header.clientHeight) - (footer.clientHeight) + "px";
                }
            }
        }

        stickyFooterWatch();
        window.addEventListener("resize",stickyFooterWatch);
        
    }

    static overlayWatcher(){
        ZEvents.addCustomEvent("ui", "overlayShown", `overlay.shown`);
        document.querySelectorAll(".modal").forEach( (element) => {
            element.addEventListener('shown.bs.modal', () => {
                ZEvents.triggerEvent("ui","overlayShown");
            });
        });
    }

    static hashScrollWatcher(){
        
        document.querySelectorAll("a[href^='#']").forEach( element => {
            let elementID = element.getAttribute("href");
            if( elementID == "#" ){ return; }
            if( document.querySelectorAll(elementID).length > 0 ){
                let targetElement = document.querySelector(elementID);
                element.addEventListener("click", (e) => {
                    e.preventDefault();
                    let verticalOffset = 0;
                    if( document.querySelector(elementID).getAttribute("data-scroll-offset") ){
                        verticalOffset = document.querySelector(elementID).getAttribute("data-scroll-offset");
                    }
                    
                    //Checks if header is position fixed and interfers with element's visibility
                    if( document.querySelector("header") ){
                        var headerPosition = window.getComputedStyle(document.querySelector('header')).getPropertyValue('position');
                        if( headerPosition == "fixed" ){
                            verticalOffset += document.querySelector("header").clientHeight;
                        }
                    }

                    window.scrollTo(
                        {
                            top : document.querySelector(elementID).offsetTop - verticalOffset,
                            behavior : "smooth"
                        }
                    );
                } );

            }
        });
    }

}