//Vendors
import { Fancybox } from "@fancyapps/ui";
import { Utils } from "../utils";

export class FancyboxHelper {

    static lightBoxInstancer = null;
    static fancyboxContainerClass = ".fancybox__container";
    static fancyBoxNotesSelector = `[data-${Utils.getVarNS()}-fancybox-notes`;
    static fancyBoxFooterSelector = `[data-${Utils.getVarNS()}-fancybox-footer]`;
    static fancyBoxFooterAttrName = `data-${Utils.getVarNS()}-fancybox-footer`;
    static tempHiddenClass = "footnote-measuring";
    static visualHiddenSwapTime = 500;

    static init() {
        //Loading vendors
        this.lightBoxInstancer = Fancybox.bind("[data-fancybox]", this.fancyBoxOptions());
    }

    static fancyBoxOptions() {
        return {
            Thumbs: {
                autoStart: false
            },
            Carousel: {
                friction : false,
                on : {
                    selectSlide : (slide) => {
                        //Enables notes bar functionality
                        this.enableHTMLNotesBar(slide);
                    }
                }
            }
        };
    }
    
    /**
     * Enables HTML slide foot notes
     *
     * @static
     * @memberof FancyboxHelper
     */
    static enableHTMLNotesBar(slide){

        var slideIndex = slide.page;
        var slides = slide.slides;
        var fancyBoxContainer = slide.$container.closest(this.fancyboxContainerClass);
        var footerElement = null;
        var tallestFootContent = 0;

        fancyBoxContainer.classList.add("--has-footnotes");

        //We need to create a footer element that is going to contain our notes otherwise animations can be glitchy
        if( fancyBoxContainer.querySelectorAll(this.fancyBoxFooterSelector).length == 0 ){
            var footerElement = document.createElement("div");
            footerElement.setAttribute(this.fancyBoxFooterAttrName, "");
            fancyBoxContainer.appendChild(footerElement);
            footerElement = fancyBoxContainer.querySelector(this.fancyBoxFooterSelector);
        }else{
            footerElement = fancyBoxContainer.querySelector(this.fancyBoxFooterSelector);
        }

        /*
        //We check for the tallest footnote content and we apply the height to the the footnotes element
        slides.forEach( (element, index) => {
            if( element.footHtml && document.querySelector(element.footHtml) ){
                document.querySelector(element.footHtml).classList.add(this.tempHiddenClass);
                if( document.querySelector(element.footHtml).clientHeight > tallestFootContent ){
                    tallestFootContent = document.querySelector(element.footHtml).clientHeight;
                }
                setTimeout(() => {
                    document.querySelector(element.footHtml).classList.remove(this.tempHiddenClass);
                }, this.visualHiddenSwapTime);
            }
        });
        
        if( tallestFootContent > 0 ){
            footerElement.style.height = tallestFootContent + "px";
        }
        */

        slides.forEach( (element,index) => {
            if( index == slideIndex ){
                if( element.footHtml && document.querySelector(element.footHtml) ){
                    if( footerElement.querySelector(this.fancyBoxNotesSelector) ){
                        footerElement.querySelector(this.fancyBoxNotesSelector).remove();
                    }
                    if( document.querySelectorAll(element.footHtml).length > 0 ){
                        var noteContentNode = document.querySelector(element.footHtml).firstElementChild.cloneNode(true);
                        footerElement.appendChild(noteContentNode);
                    }
                }else if(footerElement !== null ){
                    if( footerElement.querySelector(this.fancyBoxNotesSelector) ){
                        footerElement.querySelector(this.fancyBoxNotesSelector).remove();
                    }
                }
            }
        });
    }

}