export class Icon {
  cssClass = "c-icon";
  xlinkPrefix = "icon-";

  constructor(el = null) {
    this.el = el; 
  }

  changeIcon(iconName) {
    var useElement = this.el.querySelector("use");
    var iconHref = useElement.getAttribute("xlink:href");
    iconHref = iconHref.split('#').shift();
    useElement.setAttribute("xlink:href", iconHref + "#" + this.xlinkPrefix + iconName );
  }

}