import { on } from "delegated-events";
import { Utils } from "../../assets/js/helpers/utils";

export class Header {
  static cssClass = "c-header";
  static cssClassNavMainActive = "zaux-navmain-active";
  static cssClassSearchOpen = this.cssClass + "--search-open";

  constructor(el = null) {
    this.el = el; 
    this.init();
  }

  static get navMainActive() {
    return document.body.classList.contains(this.cssClassNavMainActive);
  }

  static set navMainActive(value) {
    document.body.classList[value === true ? 'add' : 'remove'](this.cssClassNavMainActive);
  }

  setCssVariables(){
      var sheet = document.styleSheets[0];
      sheet.deleteRule(":root{--header-height:${this.height()}px}");
      sheet.insertRule(`:root{--header-height:${this.height()}px}`);
  }

  /**
   * Return the Header's height
   *
   * @readonly
   * @static
   * @memberof Header
   */
  static get height() {
    return document.querySelector(`.${this.cssClass}`).instance.height - 1;
  }
  
  /**
   * Return the Header's height
   *
   * @readonly
   * @memberof Header
   */
  height() {
    return this.el.clientHeight;
  }

  init(){
    this.setCssVariables();
    window.addEventListener("resize", () => { this.setCssVariables() } );
  }

}