import ImageCompare from "image-compare-viewer";
import { Utils } from "../../assets/js/helpers/utils";

export class ComparisonSlider {
    cssClass = "c-comparisonslider";
    comparisonSelector = `[data-${Utils.getVarNS()}-comparison]`;

    constructor(el = null) {
        this.el = el;
        this.init();
    }

    init() {
        var comparisonElement = this.el.querySelector(this.comparisonSelector);
        const viewer = new ImageCompare(comparisonElement).mount();
    }

}