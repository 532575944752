import { Utils } from "./utils";

export class AppState{

    static boot(){
        Utils.getNS().appState = {};
    }

    static setState(entryName, value){
        Utils.getNS().appState[entryName] = value;
    }

    static getState(entryName){
        return Utils.getNS().appState[entryName];
    }

}